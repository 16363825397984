<template>
  <div>
    <BatchList v-if="active == 'first'" :batchType="batchType" :exchangeType="exchangeType" @export="exportList" ref="first">
      <template #topRight>
        <el-button size="mini" style="width:100px;margin:10px;" icon="el-icon-plus" type="primary" @click="addSingleShow=true">单个发放</el-button>
        <el-button size="mini" style="width:100px;margin:0px 10px;" icon="el-icon-plus" type="primary" @click="addBatchShow=true">批量发放</el-button>
      </template>
    </BatchList>
    <AddBatch v-if="addBatchShow" ref="AddBatch" :show.sync="addBatchShow" :batchType="batchType" :exchangeType="exchangeType" @submit="submitBatch"></AddBatch>
    <AddSingle v-if="addSingleShow" :show.sync="addSingleShow" :batchType="batchType" :exchangeType="exchangeType" @submit="submitSingle"></AddSingle>
  </div>
</template>
<script>

import BatchList from '@/components/BatchList.vue'
import AddBatch from '@/components/AddBatch.vue'
import AddSingle from '@/components/AddSingle.vue'
import { exportCommon } from "@/utils/utils";
import { realConfirmImport, addRealNameOrDirect } from "@/api/integrals";
export default {
  name: "jobNumber",
  components: {
    BatchList,
    AddBatch,
    AddSingle
  },
  data() {
    return {
      isAddDialog: false,
      active: 'first',
      batchType: '',
      exchangeType: '',
      addBatchShow: false,
      addSingleShow: false,
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    onLoad() {
      this.active = 'first'
      this.batchType = this.$route.meta.batchType
      this.exchangeType = this.$route.meta.exchangeType
      console.log('this.batchType = ', this.$route, this.batchType)
    },
    exportList(row) {
      console.log(row)
      let param = {
        batchId: row.batchId,
        batchType: row.batchType
      }
      exportCommon("/wlynIntegral/exportExcel", param);
    },
    submitBatch(list, title, auditCode, fileUrl, submitOrg) {
      submitOrg = (this.batchType == 6 || this.batchType == 5) ? submitOrg : ''
      realConfirmImport(this.exchangeType, title, list, auditCode, fileUrl, submitOrg).then(response => {
        this.addBatchShow = false
        this.$refs.first.getList()
        this.$message({
          type: response.code == 200 ? "success" : "error",
          message: response.msg || response.error_msg || response.message,
          duration: 1500
        });
      }).catch(err => {
        this.addBatchShow = false
        this.$refs.first.getList()
        this.$message.warning('保存失败')
      })
    },
    submitSingle(form) {
      addRealNameOrDirect(form).then(response => {
        if (response.code == 200) {
          this.addSingleShow = false
          this.$refs.first.getList()
        }
        this.$message({
          type: response.code == 200 ? "success" : "error",
          message: response.msg || response.error_msg || response.message,
          duration: 1500
        });
      });
    }
  }
}
</script>